<script setup lang="ts">
import type { AnyText } from '~/types/text';

const props = defineProps<{ text: AnyText }>();

const ensureArray = <T>(value: T | T[]): T[] => Array.isArray(value) ? value : [value];

const lines = computed(() => ensureArray(props.text));
</script>

<template>
    <div>
        <template
            v-for="(line, index) in lines"
            :key="index"
        >
            <template v-if="typeof line === 'string'">
                <span class="text">{{ line }}</span>
            </template>
            <template v-else>
                <CommonSmartString
                    :value="line.smartString"
                    :highlight-style="line.highlightStyle"
                    :icon-style="line.iconStyle"
                />
            </template>

            <br v-if="index < lines.length - 1">
        </template>
    </div>
</template>

<style scoped lang="scss">
.text {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-align: inherit;
    text-transform: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    font-style: inherit;
}
</style>
